body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.order-tracking {
  text-align: center;
  width: 20%;
  position: relative;
  display: block
}

.order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  border: 0 solid #afafaf;
  background-color: #f7be16;
  margin: 0 auto;
  transition: background .25s linear;
  -webkit-transition: background .25s linear;
  z-index: 2
}

.order-tracking.completed .is-complete {
  border-color: #27aa80;
  border-width: 0;
  background-color: #27aa80
}

.order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1
}

.order-tracking p {
  color: #a4a4a4;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px
}

.order-tracking p span {
  font-size: 14px
}

.order-tracking.completed p {
  color: #000
}

.order-tracking:before {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background-color: #f7be16;
  top: 3px;
  position: absolute;
  left: -50%;
  z-index: 0
}

.order-tracking:first-child:before {
  display: none
}

.order-tracking.completed:before {
  background-color: #27aa80
}

.btnpay {
  background-color: #2ee6c1
}

.btnpay,
.btnview {
  color: #fff;
  padding: 3px 20px;
  border-radius: 20px;
  width: 110px;
  margin-top: 15px
}

.btnview {
  background-color: #f1c868
}

.hide {
  display: none
}

.hidden:before {
  background-color: #d2eae2 !important;
  color: #d2eae2 !important;
  border: 1px solid #d2eae2 !important
}

.hidden {
  border-color: #d2eae2 !important
}

.hidden p {
  color: #ccc !important
}

.hiddens {
  background-color: #d2eae2 !important
}

.App {
  text-align: center
}

.App-logo {
  height: 40vmin;
  pointer-events: none
}

@media (prefers-reduced-motion:no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin 20s linear infinite;
    animation: App-logo-spin 20s linear infinite
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff
}

.App-link {
  color: #61dafb
}

@-webkit-keyframes App-logo-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes App-logo-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

.bg-gradient-inactive {
  background-image: linear-gradient(160deg, #8c8c8c, #b3b3b3, #949697) !important;
}

.top-label label:not(.form-check-label), .top-label > span:last-of-type {
  font-size: 1em !important;
  color: #1975ba !important;
  font-weight: 500;
}
.form-control {
  font-size: 1.2em !important;
  color: #212529;
}
.text-muted {
  font-size: 14px !important;
  color: #858282 !important;
}
.display-8 {
  font-size: 16px !important;
}
.blk-c {
  color: #000 !important;
}
.input-error {
  color: red;
}